<template>
  <div>
    <v-card flat>
      <v-tabs vertical class="my-tabs">
        <v-tab> <v-icon left> mdi-web </v-icon> Pays/Departments/Villes </v-tab>
        <v-tab> <v-icon left> mdi-store </v-icon>Formes Juridiques </v-tab>
        <v-tab>
          <v-icon left> mdi-file-document-multiple-outline </v-icon>Plan
          Comptable/Journaux
        </v-tab>
        <v-tab>
          <v-icon left> mdi-weight-kilogram </v-icon>Unitées de mesures
        </v-tab>
        <v-tab>
          <v-icon left> mdi-weight-kilogram </v-icon>Unitées de items
        </v-tab>
        <v-tab> <v-icon left> mdi-bank </v-icon> Banques </v-tab>
        <v-tab>
          <v-icon left> mdi-bank-circle-outline </v-icon> Agences de banque
        </v-tab>
        <v-tab> <v-icon left> mdi-cash-100 </v-icon> Paiements </v-tab>
        <v-tab
          ><v-icon left> mdi-account-details </v-icon> Type de Clients
        </v-tab>
        <v-tab>
          <v-icon left> mdi-card-account-details-outline </v-icon> Fonctions
        </v-tab>

        <v-tab>
          <v-icon left> mdi-package-variant-closed-check </v-icon>Type de
          Produits
        </v-tab>

        <v-tab> <v-icon left> mdi-ferry </v-icon>Port/Aéroport </v-tab>
        <v-tab>
          <v-icon left> mdi-script-text-outline </v-icon>Frais
          d'approches/Droits/Taxes
        </v-tab>

        <v-tab>
          <v-icon left> mdi-folder-arrow-left-right-outline </v-icon>Type de
          Mouvements
        </v-tab>
        <v-tab>
          <v-icon left> mdi-file-key-outline </v-icon> Codes Documents
        </v-tab>

        <v-tab> <v-icon left> mdi-domain </v-icon>Sites </v-tab>
        <v-tab>
          <v-icon left> mdi-office-building-cog-outline </v-icon>Options
        </v-tab>

        <!-- Tab Countrys -->
        <v-tab-item>
          <v-container fluid>
            <v-row
              ><v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="country"
                  :items="countrys"
                  :item-text="'label'"
                  :item-value="'id'"
                  label="Pays"
                  return-object
                  @change="OpenPaysForm"
                  :loading="Loading"
                ></v-select> </v-col
            ></v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :list="departments"
                  :title="'Departement'"
                  :qDelete="Qdeptdelete"
                  :qCreate="Qdeptcreate"
                  :qUpdate="Qdeptupdate"
                  :Get_suf="'Departement'"
                  :headers="departmentsheaders"
                  :master="true"
                  :key="kd"
                  :add="true"
                  :add_disable="country.id != null"
                  :del_disable="villes.length > 0"
                  :del="true"
                  :Update="true"
                  @rowselect="OpenDeptForm"
                  @delete="kd++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :list="villes"
                  :title="'Ville'"
                  :qDelete="Qvilledelete"
                  :qCreate="Qvillecreate"
                  :qUpdate="Qvilleupdate"
                  :headers="villesheaders"
                  :Get_suf="'Ville'"
                  :master="true"
                  :key="kv"
                  :add="true"
                  :Update="true"
                  :add_disable="dept.id != null"
                  :del="true"
                  @rowselect="OpenVilleForm"
                  @delete="kv++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Formes juridiques -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-select
                  v-model="country"
                  :items="countrys"
                  :item-text="'label'"
                  :item-value="'id'"
                  label="Pays"
                  return-object
                  @change="OpenPaysForm"
                  :loading="Loading"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="12">
                <listitems
                  :list="formes"
                  :title="'Formes Juridiques'"
                  :qDelete="Qformedelete"
                  :qCreate="Qformecreate"
                  :qUpdate="Qformeupdate"
                  :Get_suf="'FormeJuridique'"
                  :headers="formesheaders"
                  :master="true"
                  :key="kf"
                  :add="true"
                  :Update="true"
                  :add_disable="country.id != null"
                  :del="true"
                  @rowselect="OpenFormeForm"
                  @delete="kf++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Plan Comptable -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <listitems
                :list="plancomptable"
                :title="'Plan Comptable'"
                :qDelete="Qpcdelete"
                :qCreate="Qpccreate"
                :qUpdate="Qpcupdate"
                :headers="pcheaders"
                :Get_suf="'PlanComptable'"
                :master="true"
                :key="kpc"
                :add="true"
                :Update="true"
                :del="true"
                @rowselect="OpenPCForm"
                :del_disable="plc.tierCount > 0"
                @delete="kpc++"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <listitems
                :list="journals"
                :title="'Journaux'"
                :qDelete="Qjournaldelete"
                :qCreate="Qjournalcreate"
                :qUpdate="Qjournalupdate"
                :headers="journalsheaders"
                :Get_suf="'Journal'"
                :master="true"
                :key="kjourn"
                :add="true"
                :Update="true"
                :del="true"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Unites de Mesure -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Categories d\'unités'"
                  :list="unitcategories"
                  :qDelete="Qucdelete"
                  :qCreate="Quccreate"
                  :qUpdate="Qucupdate"
                  :headers="unitcatheaders"
                  :Get_suf="'UnitCategory'"
                  :master="true"
                  :key="kuc"
                  :add="true"
                  :Update="true"
                  :del="true"
                  @rowselect="OpenUnitCatForm"
                  :del_disable="unit_list.length > 0"
                  @delete="kuc++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <listitems
                  :title="'Unités'"
                  :list="unit_list"
                  :qDelete="Qunitdelete"
                  :qCreate="Qunitcreate"
                  :qUpdate="Qunitupdate"
                  :Get_suf="'Unit'"
                  :showstd="true"
                  :headers="unitsheaders"
                  :master="true"
                  :key="ku"
                  :add="true"
                  :Update="true"
                  :add_disable="uc.id != null"
                  :del="true"
                  @rowselect="OpenUnitForm"
                  @delete="ku++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Unites des Items -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :title="'Unités de colisage des items'"
                  :list="unit_colis_list"
                  :qDelete="Qunitdelete"
                  :qCreate="Qunitcreate"
                  :qUpdate="Qunitupdate"
                  :Get_suf="'Unit'"
                  :headers="unitscolisheaders"
                  :showstd="true"
                  :master="true"
                  :key="kc"
                  :add="true"
                  :Update="true"
                  :del="true"
                  @rowselect="OpenUnitForm"
                  @delete="kc++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Banques -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :list="list_banks"
                  :title="'Banques'"
                  :qDelete="Qbankdelete"
                  :qCreate="Qbankcreate"
                  :qUpdate="Qbankupdate"
                  :headers="banksheaders"
                  :Get_suf="'Bank'"
                  :master="true"
                  :key="kbank"
                  :showstd="true"
                  :add="true"
                  :Update="true"
                  :del="true"
                  @delete="kbank++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Agences Banques -->
        <v-tab-item>
          <v-container fluid>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <listitems
                  :list="list_agences"
                  :title="'Agences Bancaires'"
                  :qDelete="Qagencedelete"
                  :qCreate="Qagencecreate"
                  :qUpdate="Qagenceupdate"
                  :headers="agencesheaders"
                  :Get_suf="'Agence'"
                  :master="true"
                  :key="kagence"
                  :showstd="true"
                  :add="true"
                  :Update="true"
                  :del="true"
                  @rowselect="OpenAgenceForm"
                  @delete="kagence++"
                  :laoding="Loading"
                >
                </listitems>
              </v-col>
            </v-row>
          </v-container>
        </v-tab-item>
        <!-- Tab Modes de Paiement -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <listitems
                :list="modes"
                :title="'Modes de Paiement'"
                :qDelete="Qmodedelete"
                :qCreate="Qmodecreate"
                :qUpdate="Qmodeupdate"
                :headers="modesheaders"
                :Get_suf="'ModePaiement'"
                :master="true"
                :key="kmode"
                :add="true"
                :Update="mode.id > 4"
                :del="true"
                @rowselect="OpenModePaiementForm"
                :del_disable="mode.id <= 4"
                @delete="kmode++"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <listitems
                :list="modalites"
                :title="'Modalités de Paiement'"
                :qDelete="Qmodalitedelete"
                :qCreate="Qmodalitecreate"
                :qUpdate="Qmodaliteupdate"
                :headers="modalitesheaders"
                :Get_suf="'Modalite'"
                :master="true"
                :key="kmodalite"
                :add="true"
                :Update="true"
                :del="true"
                @rowselect="OpenModaliteForm"
                @delete="kmodalite++"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Types de Clients -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span>Types de Clients</span>
              <listitems
                :list="tiertypes"
                :title="'Type de Clients'"
                :qDelete="Qtiertypedelete"
                :qCreate="Qtiertypecreate"
                :qUpdate="Qtiertypeupdate"
                :headers="tiertypesheaders"
                :Get_suf="'TierType'"
                :master="true"
                :key="ktiertype"
                :add="true"
                :Update="true"
                :del="true"
                @delete="ktiertype++"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Fonctions -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="5" md="5">
              <listitems
                :list="fonctions"
                :title="'Postes de Travail'"
                :qDelete="Qfonctiondelete"
                :qCreate="Qfonctioncreate"
                :qUpdate="Qfonctionupdate"
                :headers="fonctionsheaders"
                :Get_suf="'Fonction'"
                :showstd="true"
                :master="true"
                :key="kfonction"
                :add="true"
                :Update="true"
                :del="true"
                :del_disable="fonction.agentcount > 0"
                @rowselect="OpenFonctionForm"
                @delete="kfonction++"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
            <v-col cols="12" sm="7" md="7">
              <listitems
                :list="fonction_agents"
                :title="'Agents'"
                :headers="agentsheaders_fonction"
                :master="true"
                :key="kagent"
                :add="false"
                :Update="false"
                :del="false"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Types de Produits -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <listitems
                :list="products_types"
                :title="'Type de Produit'"
                :qDelete="Qproducttypedelete"
                :qCreate="Qproducttypecreate"
                :qUpdate="Qproducttypeupdate"
                :headers="products_typesheaders"
                :Get_suf="'ProductType'"
                :master="true"
                :key="kproducttype"
                @rowselect="OpenTypeProductForm"
                :add="true"
                :Update="false"
                :del="false"
                :del_disable="true"
                @delete="kproducttype++"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Ports Aeroports -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <listitems
                :list="port_list"
                :title="'Ports/Aéroport'"
                :qDelete="Qportdelete"
                :qCreate="Qportcreate"
                :qUpdate="Qportupdate"
                :headers="portsheaders"
                :Get_suf="'Port'"
                :master="true"
                :showstd="true"
                :key="kport"
                :add="true"
                :Update="true"
                :del="true"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Frais Approches -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <listitems
                :list="approches"
                :title="'Frais d\'approche'"
                :qDelete="Qapprochedelete"
                :qCreate="Qapprochecreate"
                :qUpdate="Qapprocheupdate"
                :headers="approchesheaders"
                :Get_suf="'Approche'"
                :master="false"
                :showstd="true"
                :key="kapp"
                :add="true"
                :Update="true"
                :del="true"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Types de Mouvements -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <listitems
                :list="mvms_types"
                :title="'Type de Mouvements'"
                :qUpdate="Qmvmtypeupdate"
                :headers="mvms_typesheaders"
                :Get_suf="'MouvmentType'"
                :master="true"
                :showstd="true"
                :key="kmvmtype"
                :add="false"
                :Update="true"
                :del="false"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Codes Documents -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <listitems
                :list="docs_types"
                :title="'Code Document'"
                :qUpdate="Qdoctypeupdate"
                :headers="docs_typesheaders"
                :Get_suf="'Document'"
                :master="true"
                :key="kdoctype"
                :add="false"
                :Update="true"
                :del="false"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Sites -->
        <v-tab-item>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <span>Site</span>
              <v-treeview
                :active.sync="active"
                v-model="active"
                :items="items"
                :open.sync="open"
                :item-key="'id'"
                :item-text="'label'"
                activatable
                color="primary"
                transition
                @update:open="onOpen"
                @update:active="onActive"
                :return-object="true"
                selection-type="independent"
                open-all
              >
                <template v-slot:label="{ item }">
                  <v-chip :color="item.color">
                    {{ item.prop_name ? item.prop_name + ":" : "" }}
                    {{ item.label }}
                  </v-chip>
                </template>
              </v-treeview>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <span>Exercices</span>
              <listitems
                :list="exercices"
                :qDelete="Qexercicedelete"
                :qCreate="Qexercicecreate"
                :qUpdate="Qexerciceupdate"
                :headers="exercicesheaders"
                :Get_suf="'Exercice'"
                :master="true"
                :key="kexercice"
                :showstd="true"
                :add="true"
                :Update="exercice.exercice == $store.state.exercice"
                @rowselect="OpenExerciceForm"
                :del_disable="exercice.exercice > $store.state.exercice"
                @delete="kexercice++"
                @change="refresh"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <span>Comptes</span>
              <listitems
                :list="comptes_site"
                :qDelete="Qcomptedelete"
                :qCreate="Qcomptecreate"
                :qUpdate="Qcompteupdate"
                :headers="comptesheaders"
                :Get_suf="'SiteCompte'"
                :master="true"
                :key="kcompte"
                :add="true"
                :showstd="true"
                :add_disable="site.id > 0"
                :Update="true"
                :del="true"
                @rowselect="OpenCompteForm"
                @delete="kcompte++"
                @change="refresh"
                :laoding="Loading"
              >
              </listitems>
            </v-col>
          </v-row>
        </v-tab-item>
        <!-- Tab Options -->
        <v-tab-item>
          <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
            <v-card flat>
              <v-toolbar>
                <v-app-bar-nav-icon></v-app-bar-nav-icon>
                <v-toolbar-title>Options</v-toolbar-title>

                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  @click="save_options"
                  :disabled="change == 1"
                >
                  <v-icon left> mdi-content-save </v-icon>
                  Enregistrer
                </v-btn>
              </v-toolbar>
              <v-progress-linear
                indeterminate
                color="purple"
                height="8"
                v-if="progress"
              ></v-progress-linear>
              <v-card-text>
                <v-row class="mt-8">
                  <v-col cols="12" sm="6" md="1">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.doc_top"
                      label="Marge Top "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="1">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.doc_bottom"
                      label="Marge Inferieure "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="1">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.doc_left"
                      label="Marge Gauche "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="1">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.doc_right"
                      label="Marge Droite "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12" sm="1" md="2">
                    <v-checkbox
                      v-model="det_fact"
                      dense
                      label="Detail Mvms sur facture"
                    ></v-checkbox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.tol_qte_cmd_plus"
                      label="Tolérence livraison des commandes"
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="1">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.default_product_tva"
                      label="TVA Produit "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="1">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.default_service_tva"
                      label="TVA Service "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="2">
                    <v-switch
                      v-model="options.code_produit"
                      :label="'Code Production specifique'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-switch
                      v-model="options.code_list"
                      :label="'Code Liste specifique'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-switch
                      v-model="options.lib_com"
                      :label="'Libelle Commercial'"
                    ></v-switch>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-switch
                      v-model="options.rcpt_wo"
                      :label="'Reception sans Commande'"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="2" md="2">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.seuil_justif_stock"
                      label="Seuil Justification Stock "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.taux_excee"
                      label="Taux de dépassement items "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="2" md="2">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.expire_delai"
                      label="Délai de rappel (jours) "
                      dense
                      hint="Délai de rappel expiration par default des produits"
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" md="2">
                    <v-select
                      v-model="options.fifo"
                      :items="['FIFO', 'LIFO', 'FEFO']"
                      label="Sortie Stock"
                      dense
                      clearable
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.cmpt_inv"
                      label="Comptage Inventaire"
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="2">
                    <v-text-field
                      autocomplete="off"
                      type="number"
                      outlined
                      v-model.number="options.max_file_size"
                      label="Taille Max des fichiers(Mo) "
                      dense
                      hide-spin-buttons
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-card>
                      <v-card-title>Comptabilité</v-card-title>
                      <v-card-subtitle>
                        <v-checkbox
                          v-model="options.transf_cmpt"
                          dense
                          label="Transfert Comptable"
                        ></v-checkbox>
                      </v-card-subtitle>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" sm="6" md="2">
                            <v-select
                              v-model="options.jrn_vente"
                              :items="journals"
                              :item-text="'code'"
                              :item-value="'id'"
                              label="Journal vente"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  options.transf_cmpt == 0 ||
                                  'Journal obligatoire',
                              ]"
                              :disabled="options.transf_cmpt == 0"
                              :loading="Loading"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="2">
                            <v-select
                              v-model="options.jrn_achat"
                              :items="journals"
                              :item-text="'code'"
                              :item-value="'id'"
                              label="Journal achat"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  options.transf_cmpt == 0 ||
                                  'Journal obligatoire',
                              ]"
                              :disabled="options.transf_cmpt == 0"
                              :loading="Loading"
                            ></v-select>
                          </v-col>
                          <v-col cols="12" sm="6" md="2">
                            <v-autocomplete
                              v-model="options.cmpt_client"
                              :items="plancomptable"
                              :filter="customFilter"
                              :item-value="'id'"
                              label="Compte Clients"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  options.transf_cmpt == 0 ||
                                  'Compte obligatoire',
                              ]"
                              :disabled="options.transf_cmpt == 0"
                              :loading="Loading"
                              clearable
                            >
                              <template v-slot:selection="{ item }">
                                <!-- HTML that describe how select should render selected items -->
                                {{ item.account_number }}
                              </template>
                              <template v-slot:item="{ item }">
                                {{ item.account_number + "-" + item.label }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="2">
                            <v-autocomplete
                              v-model="options.cmpt_fournis"
                              :filter="customFilter"
                              :items="plancomptable"
                              :item-value="'id'"
                              label="Compte Fournisseurs"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  options.transf_cmpt == 0 ||
                                  'Compte obligatoire',
                              ]"
                              :disabled="options.transf_cmpt == 0"
                              :loading="Loading"
                            >
                              <template v-slot:selection="{ item }">
                                <!-- HTML that describe how select should render selected items -->
                                {{ item.account_number }}
                              </template>
                              <template v-slot:item="{ item }">
                                {{ item.account_number + "-" + item.label }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="2">
                            <v-autocomplete
                              v-model="options.cmpt_tva_achat"
                              :filter="customFilter"
                              :items="plancomptable"
                              :item-value="'id'"
                              label="Compte TVA Achat"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  options.transf_cmpt == 0 ||
                                  'Compte obligatoire',
                              ]"
                              :disabled="options.transf_cmpt == 0"
                              :loading="Loading"
                            >
                              <template v-slot:selection="{ item }">
                                <!-- HTML that describe how select should render selected items -->
                                {{ item.account_number }}
                              </template>
                              <template v-slot:item="{ item }">
                                {{ item.account_number + "-" + item.label }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="2">
                            <v-autocomplete
                              v-model="options.cmpt_tva_vente"
                              :filter="customFilter"
                              :items="plancomptable"
                              :item-value="'id'"
                              label="Compte TVA Vente"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  options.transf_cmpt == 0 ||
                                  'Compte obligatoire',
                              ]"
                              :disabled="options.transf_cmpt == 0"
                              :loading="Loading"
                            >
                              <template v-slot:selection="{ item }">
                                <!-- HTML that describe how select should render selected items -->
                                {{ item.account_number }}
                              </template>
                              <template v-slot:item="{ item }">
                                {{ item.account_number + "-" + item.label }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="12" sm="6" md="2">
                            <v-autocomplete
                              v-model="options.cmpt_pf"
                              :filter="customFilter"
                              :items="plancomptable"
                              :item-value="'id'"
                              label="Compte Produits Finis"
                              :rules="[
                                (v) =>
                                  !!v ||
                                  options.transf_cmpt == 0 ||
                                  'Compte obligatoire',
                              ]"
                              :disabled="options.transf_cmpt == 0"
                              :loading="Loading"
                            >
                              <template v-slot:selection="{ item }">
                                <!-- HTML that describe how select should render selected items -->
                                {{ item.account_number }}
                              </template>
                              <template v-slot:item="{ item }">
                                {{ item.account_number + "-" + item.label }}
                              </template>
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-form>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </div>
</template>
<script>
var aujd = new Date();
let annee = aujd.getFullYear();

import DATA from "../graphql/Dictionnaire/DATA.gql";

import DELETE_DEPT from "../graphql/Dictionnaire/DELETE_DEPT.gql";
import CREATE_DEPT from "../graphql/Dictionnaire/CREATE_DEPT.gql";
import UPDATE_DEPT from "../graphql/Dictionnaire/UPDATE_DEPT.gql";

import DELETE_VILLE from "../graphql/Dictionnaire/DELETE_VILLE.gql";
import CREATE_VILLE from "../graphql/Dictionnaire/CREATE_VILLE.gql";
import UPDATE_VILLE from "../graphql/Dictionnaire/UPDATE_VILLE.gql";

import DELETE_FORME from "../graphql/Dictionnaire/DELETE_FORME.gql";
import CREATE_FORME from "../graphql/Dictionnaire/CREATE_FORME.gql";
import UPDATE_FORME from "../graphql/Dictionnaire/UPDATE_FORME.gql";

import DELETE_PC from "../graphql/Dictionnaire/DELETE_PC.gql";
import CREATE_PC from "../graphql/Dictionnaire/CREATE_PC.gql";
import UPDATE_PC from "../graphql/Dictionnaire/UPDATE_PC.gql";

import DELETE_UC from "../graphql/Dictionnaire/DELETE_UC.gql";
import CREATE_UC from "../graphql/Dictionnaire/CREATE_UC.gql";
import UPDATE_UC from "../graphql/Dictionnaire/UPDATE_UC.gql";

import DELETE_UNIT from "../graphql/Dictionnaire/DELETE_UNIT.gql";
import CREATE_UNIT from "../graphql/Dictionnaire/CREATE_UNIT.gql";
import UPDATE_UNIT from "../graphql/Dictionnaire/UPDATE_UNIT.gql";

import DELETE_BANK from "../graphql/Dictionnaire/DELETE_BANK.gql";
import CREATE_BANK from "../graphql/Dictionnaire/CREATE_BANK.gql";
import UPDATE_BANK from "../graphql/Dictionnaire/UPDATE_BANK.gql";

import DELETE_AGENCE from "../graphql/Dictionnaire/DELETE_AGENCE.gql";
import CREATE_AGENCE from "../graphql/Dictionnaire/CREATE_AGENCE.gql";
import UPDATE_AGENCE from "../graphql/Dictionnaire/UPDATE_AGENCE.gql";

import DELETE_MODE from "../graphql/Dictionnaire/DELETE_MODE.gql";
import CREATE_MODE from "../graphql/Dictionnaire/CREATE_MODE.gql";
import UPDATE_MODE from "../graphql/Dictionnaire/UPDATE_MODE.gql";

import DELETE_MODALITE from "../graphql/Dictionnaire/DELETE_MODALITE.gql";
import CREATE_MODALITE from "../graphql/Dictionnaire/CREATE_MODALITE.gql";
import UPDATE_MODALITE from "../graphql/Dictionnaire/UPDATE_MODALITE.gql";

import DELETE_FONCTION from "../graphql/Dictionnaire/DELETE_FONCTION.gql";
import CREATE_FONCTION from "../graphql/Dictionnaire/CREATE_FONCTION.gql";
import UPDATE_FONCTION from "../graphql/Dictionnaire/UPDATE_FONCTION.gql";

import DELETE_PRODUCTTYPE from "../graphql/Dictionnaire/DELETE_PRODUCTTYPE.gql";
import CREATE_PRODUCTTYPE from "../graphql/Dictionnaire/CREATE_PRODUCTTYPE.gql";
import UPDATE_PRODUCTTYPE from "../graphql/Dictionnaire/UPDATE_PRODUCTTYPE.gql";

import UPDATE_MVMTYPE from "../graphql/Dictionnaire/UPDATE_MVMTYPE.gql";
import UPDATE_DOCTYPE from "../graphql/Dictionnaire/UPDATE_DOCTYPE.gql";

import CREATE_EXERCICE from "../graphql/Dictionnaire/CREATE_EXERCICE.gql";
import UPDATE_EXERCICE from "../graphql/Dictionnaire/UPDATE_EXERCICE.gql";
import DELETE_EXERCICE from "../graphql/Dictionnaire/DELETE_EXERCICE.gql";

import DELETE_COMPTE from "../graphql/Dictionnaire/DELETE_COMPTE.gql";
import CREATE_COMPTE from "../graphql/Dictionnaire/CREATE_COMPTE.gql";
import UPDATE_COMPTE from "../graphql/Dictionnaire/UPDATE_COMPTE.gql";

import DELETE_PORT from "../graphql/Dictionnaire/DELETE_PORT.gql";
import CREATE_PORT from "../graphql/Dictionnaire/CREATE_PORT.gql";
import UPDATE_PORT from "../graphql/Dictionnaire/UPDATE_PORT.gql";

import DELETE_APPROCHE from "../graphql/Dictionnaire/DELETE_APPROCHE.gql";
import CREATE_APPROCHE from "../graphql/Dictionnaire/CREATE_APPROCHE.gql";
import UPDATE_APPROCHE from "../graphql/Dictionnaire/UPDATE_APPROCHE.gql";

import DELETE_TIERTYPE from "../graphql/Dictionnaire/DELETE_TIERTYPE.gql";
import CREATE_TIERTYPE from "../graphql/Dictionnaire/CREATE_TIERTYPE.gql";
import UPDATE_TIERTYPE from "../graphql/Dictionnaire/UPDATE_TIERTYPE.gql";

import DELETE_JOURNAL from "../graphql/Dictionnaire/DELETE_JOURNAL.gql";
import CREATE_JOURNAL from "../graphql/Dictionnaire/CREATE_JOURNAL.gql";
import UPDATE_JOURNAL from "../graphql/Dictionnaire/UPDATE_JOURNAL.gql";

import UPDATE_OPTIONS from "../graphql/Dictionnaire/UPDATE_OPTIONS.gql";
import ALLTIERS from "../graphql/Commande/ALLTIERS.gql";
//import INSERT_TIER_ENTITE from "../graphql/Dictionnaire/INSERT_TIER_ENTITE.gql";

function comparer(otherArray) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other.id == current.id;
      }).length == 0
    );
  };
}
function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    node.index = i;
    if (node.fk_parent !== "0") {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.fk_parent]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
export default {
  components: {
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
  },
  data() {
    return {
      country_id: "13",
      open: [],
      opened: [],
      opened_save: [],
      active: [],
      active_save: [],
      items: [],
      valid: true,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      unites: [
        { id: "1", text: "DA" },
        { id: "1000", text: "KDA" },
        { id: "1000000", text: "MDA" },
      ],
      selected_annee: annee,
      act_annee: annee,
      progress: false,
      Loading: false,
      tab: null,
      tiertypesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Type",
          value: "tier_type",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Type obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
      ],

      comptesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Numero de Compte ",
          value: "compte",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Numero de Compte obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Agence",
          value: "agence_id",
          selected: true,
          slot: "simple-select",
          show_list: [],
          item_value: "id",
          item_text: "agence",
        },
        {
          text: "Default",
          value: "isdefault",
          selected: true,
          slot: "checkbox",
        },
        {
          text: "Agence",
          value: "agence_id",
          hiden: true,
          edit: true,

          type: "select",
          item_value: "id",
          item_text: "agence",
          select_list: [],
          sm: 6,
          md: 6,
        },
        {
          text: "struct_id",
          value: "struct_id",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },

        {
          text: "Default",
          value: "default",
          selected: true,
          edit: true,
          hiden: true,
          slot: "checkbox",
          type: "check-box",
          sm: 6,
          md: 6,
        },
      ],
      exercicesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Exercice ",
          value: "exercice",
          slot: "href",
          rules: [(v) => !!v || "Exercice obligatoire"],
          selected: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },

        {
          text: "Objectif",
          value: "kpi",
          slot: "cur",
          selected: true,
          rules: [(v) => !!v || "Objectif obligatoire"],
          align: "end",
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Unite",
          value: "unit",
          hiden: true,
          edit: true,
          type: "select",
          select_list: [
            { id: "1000", text: "KDA" },
            { id: "1000000", text: "MDA" },
          ],
          item_value: "id",
          item_text: "text",
          sm: 4,
          md: 4,
        },
        {
          text: "Janvier",
          value: "k01",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Fevrier",
          value: "k02",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Mars",
          value: "k03",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Avril",
          value: "k04",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Mai",
          value: "k05",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Juin",
          value: "k06",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Juillet",
          value: "k07",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Aout",
          value: "k08",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Septembre",
          value: "k09",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Octobre",
          value: "k10",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Novembre",
          value: "k11",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Decembre",
          value: "k12",
          slot: "cur",
          hiden: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
      ],

      modesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Mode",
          value: "mode",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Mode obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Type",
          value: "type",
          selected: true,
          slot: "simple-select",
          edit: true,
          type: "select",
          select_list: [
            { id: "0", text: "Local" },
            { id: "1", text: "Import" },
          ],
          show_list: [
            { id: "0", text: "Local" },
            { id: "1", text: "Import" },
          ],
          item_value: "id",
          item_text: "text",
          sm: 3,
          md: 3,
        },
      ],
      mvms_typesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Type",
          value: "mouvment",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Type obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Abrev.",
          value: "abrv",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Sens",
          value: "sens",
          selected: true,
          slot: "simple-select",
          show_list: [
            { id: "1", text: "plus" },
            { id: "-1", text: "moins" },
          ],
          item_value: "id",
          item_text: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Prefix",
          value: "prefix",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Sep.Prefix",
          value: "sep_pre",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Nombre de chiffre",
          value: "nbr",
          selected: true,
          edit: true,
          type: "number",
          sm: 3,
          md: 3,
        },
        {
          text: "Sep. Année",
          value: "sep_an",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },

        {
          text: "Sep. Mois",
          value: "sep_mois",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
      ],
      docs_typesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },

        {
          text: "Document",
          value: "document",
          slot: "href",
          selected: true,
        },
        {
          text: "Prefix",
          value: "prefix",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Sep.Prefix",
          value: "sep_pre",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Nombre de chiffre",
          value: "nbr",
          selected: true,
          edit: true,
          type: "number",
          sm: 3,
          md: 3,
        },
        {
          text: "Sep. Année",
          value: "sep_an",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },

        {
          text: "Sep. Mois",
          value: "sep_mois",
          selected: true,
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
      ],
      products_typesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Type",
          value: "label",
          selected: true,
          rules: [(v) => !!v || "Type obligatoire"],
          edit: true,
          type: "text",
        },
        {
          text: "Nature",
          value: "nature",
          selected: true,
          slot: "simple-select",
          rules: [(v) => !!v || "Nature obligatoire"],
          edit: true,
          type: "select",
          item_value: "id",
          item_text: "nature",
          select_list: [
            { id: "0", nature: "Produit" },
            { id: "1", nature: "Service" },
          ],
          show_list: [
            { id: "0", nature: "Produit" },
            { id: "1", nature: "Service" },
          ],
        },
      ],

      fonctionsheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Fonction",
          value: "fonction",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Fonction obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Nb Agent",
          value: "agentcount",
          align: "end",
          slot: "cur",
          dec: 0,
          selected: true,
        },
      ],
      journalsheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          rules: [(v) => !!v || "Code obligatoire"],
          edit: true,
          type: "text",
          sm: 3,
          md: 3,
        },
        {
          text: "Libelle",
          value: "label",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Libelle obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
      ],
      agentsheaders_fonction: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Matricule",
          value: "matricule",
          selected: true,
        },
        {
          text: "Nom",
          value: "nom",
          selected: true,
        },
        {
          text: "Structure",
          value: "structure",
          selected: true,
        },
      ],
      modalitesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Modalité",
          value: "libelle",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Modalité obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Délai",
          value: "delai",
          selected: true,
          rules: [(v) => !!v || "Délai obligatoire"],
          edit: true,
          type: "number",
          sm: 2,
          md: 2,
        },
        {
          text: "Reference",
          value: "reference",
          selected: true,
          slot: "simple-select",
          rules: [(v) => !!v || "Reference obligatoire"],
          edit: true,
          type: "select",
          item_value: "id",
          item_text: "ref",
          select_list: [
            { id: "b", ref: "Date BL" },
            { id: "f", ref: "Date Facture" },
          ],
          show_list: [
            { id: "b", ref: "Date BL" },
            { id: "f", ref: "Date Facture" },
          ],
        },
      ],
      banksheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },

        {
          text: "Banque",
          value: "bank",
          selected: true,
          rules: [(v) => !!v || "Banque obligatoire"],
          slot: "href",
          edit: true,
          type: "text",
          sm: 8,
          md: 8,
        },

        {
          text: "Trimestre Fixe",
          value: "trimestre_fixe",
          selected: true,
          edit: true,
          type: "number",
          sm: 4,
          md: 4,
        },
        {
          text: "Nom Complet",
          value: "complete_name",
          selected: true,
          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },
      ],
      agencesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Code",
          value: "code",
          selected: true,
          edit: true,
          type: "text",
          sm: 6,
          md: 4,
        },
        {
          text: "Agence",
          value: "agence",
          selected: true,
          rules: [(v) => !!v || "Agence obligatoire"],
          slot: "href",
          edit: true,
          type: "text",
          sm: 6,
          md: 8,
        },
        {
          text: "Adresse",
          value: "adresse",
          selected: true,
          rules: [(v) => !!v || "Adresse obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 12,
        },
        {
          text: "Banque",
          value: "fk_bank",
          selected: true,
          slot: "simple-select",
          edit: true,
          type: "select",
          select_list: [],
          show_list: [],
          item_value: "id",
          item_text: "bank",
          default: "13",
          sm: 6,
          md: 6,
        },
        {
          text: "Pays",
          value: "fk_pays",
          selected: true,
          slot: "simple-select",
          edit: true,
          type: "select",
          select_list: [],
          show_list: [],
          item_value: "id",
          item_text: "label",
          default: "13",
          sm: 6,
          md: 6,
        },
      ],

      approchesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Libelle",
          value: "libelle",
          selected: true,
          rules: [(v) => !!v || "Libelle obligatoire"],
          slot: "href",
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Type",
          value: "type",
          selected: true,
          slot: "simple-select",
          edit: true,
          type: "select",
          select_list: [
            { id: "0", libelle: "Frais d'approche" },
            { id: "1", libelle: "Droit" },
            { id: "2", libelle: "Taxe" },
          ],
          show_list: [
            { id: "0", libelle: "Frais d'approche" },
            { id: "1", libelle: "Droit" },
            { id: "2", libelle: "Taxe" },
          ],
          item_value: "id",
          item_text: "libelle",
          sm: 6,
          md: 6,
        },
        {
          text: "Obligatoire",
          value: "isobligatoire",
          selected: true,
          slot: "checkbox",
        },
        {
          text: "Obligatoire",
          value: "obligatoire",
          selected: true,
          edit: true,
          hiden: true,
          slot: "checkbox",
          type: "check-box",
          sm: 6,
          md: 6,
        },
      ],
      portsheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Nom",
          value: "libelle",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],
          slot: "href",
          edit: true,
          type: "text",
          sm: 6,
          md: 8,
        },
        {
          text: "Pays",
          value: "fk_pays",
          selected: true,
          slot: "simple-select",
          edit: true,
          type: "select",
          select_list: [],
          show_list: [],
          item_value: "id",
          item_text: "label",
          default: "13",
          sm: 6,
          md: 6,
        },
      ],
      unitcategories: [],
      products_types: [],
      mvms_types: [],
      docs_types: [],
      countrys: [],
      banks: [],
      modes: [],
      ports: [],
      tiertypes: [],
      journals: [],
      approches: [],
      mode: {},
      modalites: [],
      fonctions: [],
      modalite: {},
      typeproduct: {},
      structures: [],
      compte: {},
      country: {},
      agence: {},
      uc: {},
      unit: {},
      dept: {},
      ville: {},
      forme: {},
      plc: {},

      unitcatheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Nom",
          value: "name",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],

          edit: true,
          type: "text",
          sm: 2,
          md: 2,
        },
      ],

      unitsheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Nom",
          value: "name",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],

          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },
        {
          text: "Facteur",
          value: "factor",
          rules: [(v) => !!v || "Facteur obligatoire"],
          selected: true,
          edit: true,
          type: "number",
          sm: 2,
          md: 2,
        },
        {
          text: "Arrondi",
          value: "rounding",
          selected: true,
          edit: true,
          default: 0,
          rules: [(v) => !v || "Arrondi obligatoire"],
          type: "number",
          sm: 2,
          md: 2,
        },
        {
          text: "category_id",
          value: "category_id",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },
        {
          text: "uom_type",
          value: "uom_type",
          default: "m",
          edit: true,
          hiden: true,
          selected: false,
        },
      ],
      unitscolisheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Nom",
          value: "name",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],

          edit: true,
          type: "text",
          sm: 12,
          md: 12,
        },

        {
          text: "uom_type",
          value: "uom_type",
          default: "c",
          edit: true,
          hiden: true,
          selected: false,
        },
      ],
      pcheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "N° Compte",
          value: "account_number",
          selected: true,
          rules: [(v) => !!v || "N° Compte obligatoire"],

          edit: true,
          type: "text",
          sm: 2,
          md: 2,
        },
        {
          text: "N° Compte Parent",
          value: "account_parent",
          selected: true,
          edit: true,
          type: "text",
          sm: 2,
          md: 2,
        },
        {
          text: "Type",
          value: "pcg_type",
          selected: true,
          rules: [(v) => !!v || "Type obligatoire"],
          edit: true,
          type: "text",
          sm: 2,
          md: 2,
        },

        {
          text: "Nom",
          value: "label",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],
          edit: true,
          slot: "href",
          type: "text",
          sm: 6,
          md: 6,
        },
      ],
      formesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Nom",
          value: "libelle",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],
          slot: "href",
          edit: true,
          type: "text",
          sm: 6,
          md: 8,
        },

        {
          text: "fk_pays",
          value: "fk_pays",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },
      ],
      departmentsheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Nom",
          value: "nom_departement",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],
          slot: "href",
          edit: true,
          type: "text",
          sm: 6,
          md: 6,
        },

        {
          text: "country_id",
          value: "country_id",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },
      ],
      villesheaders: [
        {
          text: "N°",
          value: "no",
          selected: true,
        },
        {
          text: "Nom",
          value: "nom_ville",
          slot: "href",
          selected: true,
          rules: [(v) => !!v || "Nom obligatoire"],
          edit: true,
          type: "text",
          sm: 6,
          md: 3,
        },
        {
          text: "departement_id",
          value: "departement_id",
          default: "",
          edit: true,
          hiden: true,
          selected: false,
        },
      ],
      plancomptable: [],
      villes_list: [],
      agences_list: [],
      banks_list: [],
      comptes: [],
      exercices: [],
      unit_colis_list: [],
      kp: 0,
      kd: 100,
      kv: 200,
      kf: 300,
      kpc: 400,
      kuc: 500,
      ku: 600,
      kc: 6000,
      kb: 700,
      kagence: 800,
      kmode: 900,
      kmodalite: 1000,
      kstruct: 1100,
      kcompte: 1200,
      kproducttype: 1300,
      kmvmtype: 1400,
      kdoctype: 1500,
      kexercice: 1600,
      kfonction: 1700,
      kagent: 1800,
      karret: 1900,
      kport: 2000,
      kinc: 2100,
      kapp: 2200,
      ktiertype: 2300,
      kbank: 2400,
      kjourn: 2500,
      change: 0,
      change2: 0,
      options: {},
      exercice: {},
      det_fact: true,
      site: {},
      fonction: {},
    };
  },
  mounted() {
    this.refresh();
  },
  watch: {
    options: {
      handler() {
        this.change++;
      },
      deep: true,
    },
  },
  computed: {
    Qjournalcreate() {
      return CREATE_JOURNAL;
    },
    Qjournalupdate() {
      return UPDATE_JOURNAL;
    },
    Qjournaldelete() {
      return DELETE_JOURNAL;
    },
    Qtiertypecreate() {
      return CREATE_TIERTYPE;
    },
    Qtiertypeupdate() {
      return UPDATE_TIERTYPE;
    },
    Qtiertypedelete() {
      return DELETE_TIERTYPE;
    },
    Qportcreate() {
      return CREATE_PORT;
    },
    Qportupdate() {
      return UPDATE_PORT;
    },
    Qportdelete() {
      return DELETE_PORT;
    },

    Qapprochecreate() {
      return CREATE_APPROCHE;
    },
    Qapprocheupdate() {
      return UPDATE_APPROCHE;
    },
    Qapprochedelete() {
      return DELETE_APPROCHE;
    },
    Qexercicecreate() {
      return CREATE_EXERCICE;
    },
    Qexerciceupdate() {
      return UPDATE_EXERCICE;
    },
    Qexercicedelete() {
      return DELETE_EXERCICE;
    },
    Qdoctypeupdate() {
      return UPDATE_DOCTYPE;
    },
    Qmvmtypeupdate() {
      return UPDATE_MVMTYPE;
    },
    Qproducttypecreate() {
      return CREATE_PRODUCTTYPE;
    },
    Qproducttypeupdate() {
      return UPDATE_PRODUCTTYPE;
    },
    Qproducttypedelete() {
      return DELETE_PRODUCTTYPE;
    },
    Qfonctioncreate() {
      return CREATE_FONCTION;
    },
    Qfonctionupdate() {
      return UPDATE_FONCTION;
    },
    Qfonctiondelete() {
      return DELETE_FONCTION;
    },
    Qcomptecreate() {
      return CREATE_COMPTE;
    },
    Qcompteupdate() {
      return UPDATE_COMPTE;
    },
    Qcomptedelete() {
      return DELETE_COMPTE;
    },
    Qmodalitecreate() {
      return CREATE_MODALITE;
    },
    Qmodaliteupdate() {
      return UPDATE_MODALITE;
    },
    Qmodalitedelete() {
      return DELETE_MODALITE;
    },
    Qmodecreate() {
      return CREATE_MODE;
    },
    Qmodeupdate() {
      return UPDATE_MODE;
    },
    Qmodedelete() {
      return DELETE_MODE;
    },
    Qbankcreate() {
      return CREATE_BANK;
    },
    Qbankupdate() {
      return UPDATE_BANK;
    },
    Qbankdelete() {
      return DELETE_BANK;
    },
    Qagencecreate() {
      return CREATE_AGENCE;
    },
    Qagenceupdate() {
      return UPDATE_AGENCE;
    },
    Qagencedelete() {
      return DELETE_AGENCE;
    },

    Qdeptcreate() {
      return CREATE_DEPT;
    },
    Qdeptupdate() {
      return UPDATE_DEPT;
    },
    Qdeptdelete() {
      return DELETE_DEPT;
    },
    Qvillecreate() {
      return CREATE_VILLE;
    },
    Qvilleupdate() {
      return UPDATE_VILLE;
    },
    Qvilledelete() {
      return DELETE_VILLE;
    },

    Qformecreate() {
      return CREATE_FORME;
    },
    Qformeupdate() {
      return UPDATE_FORME;
    },
    Qformedelete() {
      return DELETE_FORME;
    },

    Qpccreate() {
      return CREATE_PC;
    },
    Qpcupdate() {
      return UPDATE_PC;
    },
    Qpcdelete() {
      return DELETE_PC;
    },

    Quccreate() {
      return CREATE_UC;
    },
    Qucupdate() {
      return UPDATE_UC;
    },
    Qucdelete() {
      return DELETE_UC;
    },

    Qunitcreate() {
      return CREATE_UNIT;
    },
    Qunitupdate() {
      return UPDATE_UNIT;
    },
    Qunitdelete() {
      return DELETE_UNIT;
    },
    formes() {
      let l;
      l = this.country && this.country.formes ? this.country.formes : [];
      l.sort(this.sortBy("libelle"));
      return l;
    },
    port_list() {
      let l = [];
      l = this.ports;
      if (l.length > 0) l.sort(this.sortBy("libelle"));
      return l;
    },
    list_banks() {
      let l = [];
      l = this.banks_list;
      if (l.length > 0) l.sort(this.sortBy("bank"));
      return l;
    },
    list_agences() {
      let l = [];
      l = this.agences_list;
      if (l.length > 0) l.sort(this.sortBy("agence"));
      return l;
    },

    unit_list() {
      return this.uc && this.uc.units ? this.uc.units : [];
    },
    fonction_agents() {
      return this.fonction ? this.fonction.agents : [];
    },

    departments() {
      let l;
      l =
        this.country && this.country.departements
          ? this.country.departements
          : [];
      l.sort(this.sortBy("nom_departement"));
      return l;
    },
    villes() {
      let l;
      l = this.dept && this.dept.villes ? this.dept.villes : [];
      l.sort(this.sortBy("nom_ville"));
      return l;
    },

    comptes_site() {
      let l;
      l =
        this.site && this.comptes
          ? this.comptes.filter((elm) => elm.struct_id == this.site.id)
          : [];
      l.sort(this.sortBy("compte"));
      return l;
    },
  },
  methods: {
    customFilter(item, queryText) {
      let textlist = [];
      ["account_number", "code", "label"].forEach((element) => {
        const text1 = item[element] ? item[element].toLowerCase() : "";
        textlist.push(text1);
      });

      const searchText = queryText.toLowerCase();
      const index = textlist.findIndex((element) => {
        if (element.includes(searchText)) {
          return true;
        }
      });
      return index !== -1;
    },
    onActive() {
      this.active_save = this.active;
      this.site = this.active[0];
      this.OpenSiteForm(this.site);
    },
    onOpen(items) {
      var onlyInA = items.filter(comparer(this.opened));
      var onlyInB = this.opened.filter(comparer(items));

      let newnode = onlyInA.concat(onlyInB);
      if (newnode.length > 0) {
        let i = items.findIndex(
          (elm) =>
            elm.id != newnode[0].id && elm.fk_parent == newnode[0].fk_parent
        );
        if (i >= 0) items.splice(i, 1);

        this.opened = [];
        this.open.forEach((element) => {
          this.opened.push(element);
          this.opened_save.push(element);
        });
        this.open = items;
      }
    },
    async vider_data(item) {
      // a faire
      //password verification
      //closure
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet exercice?"
        )
      ) {
        //update tier
        let v = {
          TypeScope: 2,
          Exercice: item.exercice,
          statut_id: [2, 3],
        };
        let r = await this.requette(ALLTIERS, v);
        if (r) {
          let list = [];
          r.alltiers.forEach((element) => {
            if (
              element.situation_ini > 0 ||
              element.situation_bl_ini > 0 ||
              element.situation_ini < 0 ||
              element.situation_bl_ini < 0
            ) {
              list.push({
                tier_id: element.id,
                exercice: item.exercice + 1,
                situation_bl_ini: element.situation_bl_ini,
                situation_ini: element.situation_ini,
                create_uid: this.$store.state.me.id,
              });
            }
          });
          v = {
            list: list,
          };
          //  await this.maj(INSERT_TIER_ENTITE, v);

          this.$router.push("/");
        }
      }
    },
    async requette(query, v) {
      let r;
      this.Loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.Loading = false;
        })
        .catch((error) => {
          this.Loading = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },

    save_options() {
      if (this.$refs.form.validate()) {
        this.progress = true;
        let v = {
          options: {
            id: this.options.id,
            tol_qte_cmd_plus: this.options.tol_qte_cmd_plus,
            cmpt_inv: this.options.cmpt_inv,
            max_file_size: this.options.max_file_size,
            doc_top: this.options.doc_top,
            doc_bottom: this.options.doc_bottom,
            doc_left: this.options.doc_left,
            doc_right: this.options.doc_right,
            det_fact: this.det_fact ? 1 : 0,
            default_product_tva: this.options.default_product_tva,
            default_service_tva: this.options.default_service_tva,
            code_produit: this.options.code_produit ? 1 : 0,
            code_list: this.options.code_list ? 1 : 0,
            lib_com: this.options.lib_com ? 1 : 0,
            rcpt_wo: this.options.rcpt_wo ? 1 : 0,
            seuil_justif_stock: this.options.seuil_justif_stock,
            taux_excee: this.options.taux_excee,
            expire_delai: this.options.expire_delai,
            fifo: this.options.fifo,
            transf_cmpt: this.options.transf_cmpt ? 1 : 0,
            jrn_vente: this.options.jrn_vente,
            jrn_achat: this.options.jrn_achat,
            cmpt_client: this.options.cmpt_client,
            cmpt_fournis: this.options.cmpt_fournis,
            cmpt_tva_achat: this.options.cmpt_tva_achat,
            cmpt_tva_vente: this.options.cmpt_tva_vente,
            cmpt_pf: this.options.cmpt_pf,
            write_uid: parseInt(this.$store.state.me.id),
          },
        };
        this.maj(UPDATE_OPTIONS, v);
        this.change = 1;
      }
    },
    async refresh() {
      annee = this.$store.state.exercice;

      let r = await this.requette(DATA);
      if (r) {
        this.countrys = r.countrys;
        this.plancomptable = r.plancomptable;
        this.unitcategories = r.unitcategories;
        this.banks = r.banks;
        this.modes = r.modes;
        this.modalites = r.modalites;
        this.fonctions = r.fonctions;
        this.kfonction++;
        this.comptes = r.comptes;
        this.exercices = r.exercices;
        this.villes_list = r.villes;
        this.agences_list = r.agences;
        this.banks_list = r.banks;
        this.structures = r.structures.filter(
          (elm) => elm.type_id == 0 || elm.type_id == 1
        );
        this.items = list_to_tree(this.structures);
        this.unit_colis_list = r.units.filter((elm) => elm.uom_type == "c");
        this.products_types = r.products_types;
        this.mvms_types = r.mvms_type;
        this.docs_types = r.documents;
        this.ports = r.ports;
        this.journals = r.journals;
        this.tiertypes = r.tiertypes.filter((elm) => elm.type == "C");
        this.approches = r.approches;
        this.countrys.sort(this.sortBy("label"));
        this.country = this.countrys.find((elm) => elm.id == "13");
        this.kd++;
        this.kv++;
        this.comptesheaders[4].select_list = this.agences_list;
        this.comptesheaders[2].show_list = this.agences_list;
        this.portsheaders[2].select_list = this.countrys;
        this.portsheaders[2].show_list = this.countrys;
        this.agencesheaders[4].select_list = this.banks_list;
        this.agencesheaders[4].show_list = this.banks_list;
        this.agencesheaders[5].select_list = this.countrys;
        this.agencesheaders[5].show_list = this.countrys;

        this.options = r.options[0];
        this.det_fact = this.options.det_fact == 1 ? true : false;
        this.kb++;
        this.kagence++;
        this.kbank++;
        this.kmode++;
        this.kmodalite++;
        this.kuc++;
        this.ku++;
        this.kc++;
        this.kp++;
        this.kpc++;
        this.kstruct++;
        this.kcompte++;
        this.kexercice++;
        this.kproducttype++;
        this.kmvmtype++;
        this.kdoctype++;
        this.kport++;
        this.kinc++;
        this.kapp++;
        this.ktiertype++;
        this.open = this.opened_save;
        this.active = this.active_save;
      }
    },
    async maj(query, v) {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: query,
          variables: v,
        })
        .then((data) => {
          r = data.data;
          this.progress = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.progress = false;
        });
      return r;
    },
    sortBy(prop) {
      return function (a, b) {
        return a[prop].localeCompare(b[prop]);
      };
    },
    OpenPCForm(item) {
      this.plc = item;
    },
    OpenFormeForm(item) {
      this.forme = item;
    },
    OpenFonctionForm(item) {
      this.fonction = item;
      this.kagent++;
    },
    OpenPaysForm(item) {
      if (!item.departements) item.departements = [];
      if (!item.formes) item.formes = [];
      this.country = item;
      this.departmentsheaders[2].default = this.country.id;
      this.formesheaders[2].default = this.country.id;

      this.dept = {};
      this.kd++;
      this.kf++;
      this.kv++;
    },
    OpenModePaiementForm(item) {
      this.mode = item;
    },
    OpenTypeProductForm(item) {
      this.typeproduct = item;
    },
    OpenModaliteForm(item) {
      this.modalite = item;
    },

    OpenAgenceForm(item) {
      this.agence = item;
    },
    OpenSiteForm(item) {
      this.site = item;
      if (this.site) {
        this.comptesheaders[5].default = this.site.id;
      }
      this.kcompte++;
      this.kexercice++;
    },
    OpenExerciceForm(item) {
      this.exercice = item;
    },
    OpenCompteForm(item) {
      this.compte = item;
    },
    OpenUnitCatForm(item) {
      if (!item.units) item.units = [];
      this.uc = item;
      this.unitsheaders[4].default = this.uc.id;
      this.ku++;
    },
    OpenDeptForm(item) {
      if (!item.villes) item.villes = [];
      this.dept = item;
      this.villesheaders[2].default = this.dept.id;
      this.kv++;
    },
    OpenUnitForm(item) {
      this.unit = item;
    },
    OpenVilleForm(item) {
      this.ville = item;
    },
  },
};
</script>
<style>
div.my-tabs [role="tab"] {
  justify-content: flex-start;
  margin-right: 2em;
}
</style>
